.DashboardParentClass .section {
  padding: 10px 0px;
}
.DashboardParentClass .myexportbtn {
  border: 2px groove;
  border-radius: 4px;
  margin: 5px 0px 12px 12px;
  padding: 2px;
  height: 32px;
  margin-top: 5px;
  background: transparent;
  color: #222226;
  font-size: 12px;
  font-weight: 400;
}
.DashboardParentClass .section2 .dispflex {
  display: flex;
}
.DashboardParentClass .section2 .datepickerSection {
  border: 2px groove;
  border-radius: 4px;
  padding: 2px;
  height: 32px;
  margin: 5px 12px 12px 12px;
  position: relative;
  font-size: 16px;
}
.DashboardParentClass .section2 .datepickerSection .selected_m_y {
  padding: 0px 6px;
  cursor: pointer;
}
.DashboardParentClass .section2 .datepickerSection .datepickerSectionBox {
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  height: 100%;
  width: 550px;
  position: absolute;
  top: 30px;
  left: -1px;
  background-color: #ffffff;
  outline: none;
  text-align: left;
  display: inline-table;
}
.selectionSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 12px;
}
.selectionSection .selectionform {
  display: flex;
}
.selectionSection .selectionform .selectboxsection {
  padding: 9px;
  background: transparent;
  border: 1px solid #d3d3d3;
  width: 150px;
  appearance: auto !important;
}
.selectionform .form-select:focus{
    box-shadow: none;
}
.selectionSection .selectionform .checkboxsection {
  margin-left: 10px;
  margin-top: 7px;
}
.monthrowsection {
  padding: 20px 12px;
}
.monthrowsection .active .m_thumb{
    background-color: #d3d3d3;
}
.monthrowsection .m_thumb {
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  margin: 5px 0px;
}
.datepickerSectionBox .buttonSection {
  margin-top: 15px;
  border-top: 1px solid #d3d3d3;
  padding: 12px;
}
.datepickerSectionBox .buttonSection .myrecordbtn {
  color: #ffffff;
  background-color: #000000;
  border: 1px solid #000000;
  border-radius: 4px;
  padding: 4px 20px;
}
.DashboardParentClass .section2 .calsvg {
  float: left;
  vertical-align: middle;
  height: 20px;
  cursor: pointer;
}
.DashboardParentClass .text-right {
  text-align: right;
}
.DashboardParentClass .text-left {
  text-align: left;
}
.DashboardParentClass .thumbnail-section {
  padding-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
}
.DashboardParentClass .thumbnail-section .thumbnailBox {
  min-width: 252px;
  min-height: 72px;
  display: flex;
  border-style: groove;
  border-width: 2px;
  border-radius: 4px;
  padding: 8px;
  text-align: left;
}
.DashboardParentClass .iconBg {
  background-color: #f3f4f8;
  padding: 8px;
  border-radius: 25px;
  height: 40px;
  width: 40px;
}
.DashboardParentClass .secondPart {
  padding-left: 15px;
}
.DashboardParentClass .secondPart .progress {
  background-color: #b3b5bd;
}
.DashboardParentClass .secondPart .progress .progress-bar {
  background-color: #222226;
}
.DashboardParentClass .muted-text {
  font-size: 12px;
}
.DashboardParentClass .boldSolutions {
  font-size: 24px;
  font-weight: 500;
}
.DashboardParentClass .section4 {
  text-align: left;
}
.DashboardParentClass .section4 .tableborder {
  border-style: groove;
  border-width: 2px;
  border-radius: 4px;
  min-height: 450px;
  padding: 8px;
}
.DashboardParentClass .section4 .tablehead {
  font-size: 16px;
  font-weight: 400;
}
.DashboardParentClass .section4 .tablesec {
  width: 100%;
  margin-top: 15px;
}
.DashboardParentClass .section4 .tablesec td {
  padding: 8px;
}
.DashboardParentClass .section4 .fts12 {
  font-size: 12px;
}
.DashboardParentClass .section4 .fts16 {
  font-size: 16px;
}
.DashboardParentClass .section5 {
  text-align: left;
}
.DashboardParentClass .section5 .graphHead {
  font-size: 24px;
  font-weight: 500;
}
.DashboardParentClass .section5 .graphSection {
  padding: 4px 15px;
}
.DashboardParentClass .section5 .graphSectionBox {
  border-style: groove;
  border-width: 2px;
  border-radius: 4px;
  min-height: 380px;
  padding: 0px;
}
.DashboardParentClass .section5 .graphSection .graphSmallHead {
  padding: 8px;
  border-bottom-style: groove;
  border-width: 2px;
}
