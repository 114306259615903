.img-hover .enable-hoverimg{
    display: none;
}
.img-hover:hover .enable-hoverimg{
    width:150px;
    height:150px;
    border-radius:4px;
    position: absolute;
    left: 6rem;
    right: 0;
    top: 1rem;
    display:flex;
    z-index: 999;
}
.enable-hoverimg img{
    border-radius: 10px;
}

.cursor-pointer{
    cursor: pointer;
}