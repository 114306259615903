.headerTitleDiv
{
    display : block;
    float: left;
}

.headerActionButtonDiv
{
    display : block;
    float: right;
}

.subHeaderBtn
{
    border-radius: 12px;
    border: 1px solid #272A31;
    background: rgba(19, 19, 19, 0.10);
}

.contentBox
{
    border-radius: 4px;
    border: 1px solid #B3B5BD;
}

.overlayContent{
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
}

.overlayImage{
    margin-top: 30px;
    margin-bottom: 30px;
}

.overlayVideo{
    margin-top: 15px;
}

.overlayImage img{
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    height: 120px;
    border-radius: 4px;
    object-fit:cover;
}

.overlayLeftContent{
    width: 150px;
}

.overlayRightContent{
    width: 100px;
}