.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.headerFont
{
  font-size: 24px;
  font-weight: 500;
}

.font12Bold
{
  font-size: 12px;
  font-weight: bold;
}

.font24Bold
{
  font-size: 24px;
  font-weight: bold;
}


.font12Light
{
  font-size: 12px;
  font-weight: lighter;
}

.font16Light
{
  font-size: 16px;
  font-weight: lighter;
}

.font14Light
{
  font-size: 14px;
  font-weight: lighter;
}

.paddingTopBottom10
{
    padding-top : 10px;
    padding-bottom: 10px;
}

.paddingLeftRight10
{
    padding-left : 10px;
    padding-right: 10px;
}

.paddingTopBottom20
{
    padding-top : 20px;
    padding-bottom: 20px;
}

.padding10
{
    padding-top : 10px;
    padding-bottom: 10px;
    padding-left : 10px;
    padding-right: 10px;
}

.padding20
{
    padding-top : 20px;
    padding-bottom: 20px;
    padding-left : 20px;
    padding-right: 20px;
}

.sidePadding0
{
  padding-left: 0px;
  padding-right: 0px;;
}

.noPadding
{
  padding : 0px;
}

.pointer {
  cursor: pointer;
}

.paddingBottom5 {
  padding-bottom: 5px;
}

.paddingBottom10 {
  padding-bottom: 10px;
}

.paddingBottom20 {
  padding-bottom: 20px;
}

.paddingTop10 {
  padding-top: 10px;
}

.paddingTop5 {
  padding-top: 5px;
}

.paddingTop15 {
  padding-top: 15px;
}

.paddingTop20 {
  padding-top: 20px;
}

.paddingLeft12 {
  padding-left: 12px;
}

.textAlignLeft {
  text-align: left;
}

.textAlignCenter {
  text-align: center;
}


.menuActive
{
    background: #131313;
    color: #FFFFFF;
    border-radius: 30px;
    width: fit-content;
}

.subHeaderFont
{
  color: #222226;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.appFont12Light
{
  color: #9496A1;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.appFontLight
{
  color: #9496A1;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.font16{
  font-size: 16px;
}

.font12{
  font-size: 12px;
}

.appFont16Regular
{
  color: #222226;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.appFontInputHeader
{
  color: #272A31;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-check-input:checked
{
  background-color: #222226 !important;
  border-color: #222226 !important;
}

.boxBorder
{
    border-radius: 4px;
    border: 1px solid #B3B5BD;
}

.marginAuto
{
  margin : 0 auto;
}

.margin10
{
  margin : 10px;
}

.borderBottomForm
{
  border-bottom : 1px solid #B3B5BD;
}
.modal-header{
  border-bottom: 0px !important;
  padding-bottom: 0px !important;
}