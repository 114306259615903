.sideBarActive
{
    background: #131313;
    color: #FFFFFF;
    border-radius: 30px;
}

.logoheader{
    width: 136px;
    height: 57px;
}